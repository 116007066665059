// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-i-18-n-redirect-js": () => import("/opt/build/repo/src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-apartments-js": () => import("/opt/build/repo/src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-architects-js": () => import("/opt/build/repo/src/pages/architects.js" /* webpackChunkName: "component---src-pages-architects-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("/opt/build/repo/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("/opt/build/repo/src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

